import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useContentInProp } from '@/hooks/useContentInProp'
import { Right } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'

const AdaptablePage: React.FC = () => {
  const { t } = useTranslation()
  const inProp = useContentInProp()

  const content = {
    pretitle: t('home.adaptable_super', 'fill copy key: home.adaptable_super'),
    title: t('home.adaptable_title', 'fill copy key: home.adaptable_title'),
    text: t('home.adaptable_body', 'fill copy key: home.adaptable_body'),
    links: [
      {
        link: t(
          'home.adaptable_cta_right',
          'fill copy key: home.adaptable_cta_right',
        ),
        url: '/our-platform',
        variant: 'mdDown',
      },
      {
        link: t('home.adaptable_cta', 'fill copy key: home.adaptable_cta'),
        url: '/home/reliable',
        variant: 'all',
      },
    ],
  }

  return (
    <>
      <Right>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="right"
            delay={1.1}
            time={1}
            url="/our-platform"
            text={t(
              'home.adaptable_cta_right',
              'fill copy key: home.adaptable_cta_right',
            )}
          />
        )}
      </Right>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[<Text isMobile {...content} />]}
            >
              <Box row>
                <Box col={0.45}>
                  <Text {...content} />
                </Box>
                <Box col={0.55} />
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default AdaptablePage
